<template>
  <div>
    <p class="text-2xl mb-6">
      {{ editing ? `Editer le role : ${role.name}` : "Créer un nouveau role" }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="role.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="role.description"
                    dense
                    label="Description"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-select
                    v-model="role.permissions"
                    :items="permissionsList"
                    item-text="name"
                    item-value="id"
                    label="Permissions"
                    multiple
                    outlined
                    dense
                    :rules="[required]"
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveRole"
                >
                  Enregistrer le nouveau Role</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'roles-list' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, integerValidator } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";
export default {
  name: "RoleCreateOrUpdate",
  async created() {
    this.permissionsList = await this.fetchData("/permissions/");
    if ("id" in this.$route.params) {
      this.getRoleById(this.$route.params.id);
      // get permissions list
      this.editing = true;
    } else {
      this.editing = false;
    }
  },
  data() {
    return {
      required,
      integerValidator,
      editing: false,
      permissionsList: [],
      role: {
        pk: -1,
        name: "",
        description: "",
        permissions: "",
      },
    };
  },
  methods: {
    async fetchData(url) {
      const { data } = await axios.get(url);
      return data.items;
    },
    async saveRole() {
      const isFormValid = this.$refs.form.validate();
      if (!isFormValid) return;
      const payload = {
        name: this.role.name,
        description: this.role.description,
        permissions: this.role.permissions.join(","),
      };

      if (this.editing) {
        const { status } = await axios.put(`/roles/${this.role.pk}`, payload);
        if (status === 200) {
          await router.push({ name: "roles-list" });
        }
      } else {
        const { status } = await axios.post(`/roles/`, payload);
        if (status === 200) {
          await router.push({ name: "roles-list" });
        }
      }
    },
    async getRoleById(id) {
      const { status, data } = await axios.get(`/roles/${id}`);
      // keep only the permissions that are in the permissions list
      const permissions = data.permissions
        .split(",")
        .map((item) => parseInt(item));
      if (status === 200) {
        this.role = {
          pk: data.id,
          name: data.name,
          description: data.description,
          permissions: permissions.filter((item) =>
            this.permissionsList.find((p) => p.id === item)
          ),
        };
      }
    },
  },
};
</script>

<style scoped></style>
